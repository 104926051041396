<template>
  <quill-text-editor
    v-model:content="widgetContent"
    :toolbar="`#${toolbarContainerId}`"
    placeholder="Right click here to add image..."
  >
    <template #toolbar>
      <div
        :id="toolbarContainerId"
      >
        <span class="ql-formats">
          <button class="ql-image" />
        </span>
        <span class="ql-formats">
          <button
            class="text-gray-200 hover:text-white mr-2"
            @click="handleDeleteWidget()"
          >
            DEL
          </button>
        </span>
      </div>
    </template>
  </quill-text-editor>
</template>

<script>
import { computed } from 'vue';
import QuillTextEditor from '@/components/QuillTextEditor.vue';

export default {
    name: 'ImageWidget',

    components: {
        QuillTextEditor
    },

    props: {
        modelValue: {
            type: String,
            required: true
        },
        widgetIndex: {
            type: [String, Number],
            required: true
        }
    },

    emits: [
        'update:modelValue',
        'deleteWidget'
    ],

    setup (props, { emit }) {
        const toolbarContainerId = computed(() => 'imageWidgetToolbar' + props.widgetIndex);

        // 2-way data bind logic
        const widgetContent = computed({
            get () {
                return props.modelValue;
            },
            set (value) {
                emit('update:modelValue', value);
            }
        });

        // delete widget logic
        const handleDeleteWidget = () => {
            emit('deleteWidget');
        };

        return {
            toolbarContainerId,
            widgetContent,
            handleDeleteWidget
        };
    }
};
</script>
